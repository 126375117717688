import './App.css';
import vagn from './assets/ml.png';

function App() {
  return (
    <div>
      {/* <div style={{textAlign: 'center', padding: 200}}>2 augusti 2024</div> */}
     <div style={{ display: 'flex', justifyContent: "center"}}><img width="50%" src={vagn} alt='spårvagn'/></div>
    </div>
  );
}

export default App;
